// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Sortable from "sortablejs"
import { useHotkeys } from "stimulus-use";
import axios from "axios";


function updateVotes(id, timeDue) {
  console.log("instance ${id}");
  console.log("timeDue ${timeDue}");
  const config = { responseType: 'json' }
  let yourDate = new Date()
  const voteupdate = { instance: { scheduled_at: yourDate.toISOString().split('T')[0] + ' ' + timeDue } };
  return axios.patch("http://localhost:3000/instances/" + id + ".json", voteupdate, config).then(res => res.data);
}


function updateVotes2(id, priority) {
  console.log("instance ${id}");
  console.log("priority ${priority}");
  const config = { responseType: 'json' }
  const voteupdate = { checklist_item: { priority: priority } };
  return axios.patch("http://localhost:3000/checklist_items/" + id + ".json", voteupdate, config).then(res => res.data);
}


export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end,
      group: 'shared'
    })
    useHotkeys(this, {
      "cmd+t": [this.openPalette],
      ".": [this.editFile],
    });
  }

  openPalette(event) {
    console.log('open');
  }

  editFile(event) {
    console.log('edit');
    console.log(event);
    var elems = event.elementTargets;
    for (var i = elems.length - 1; i >= 0; i--) {
      if (elems[i].dataset.elementId == lnk) {
        elems[i].classList.add('text-blue-700')
        console.log(elems[i])
        break
      }
    }
  }

  end(event) {
    console.log(event.oldIndex);
    console.log(event.newIndex);

    if (event.item.id.split("_")[0] == 'instance') {
      updateVotes(event.item.id.split("_")[1], event.to.id);
    }
    else {
      updateVotes2(event.item.id.split("_")[1], event.newIndex);
    }

    console.log(event);

    // console.log(event.item.id.split("_")[1]);
    // console.log(event.to.id);

    // 

  }
}